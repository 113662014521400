<template>
  <div class="wi100 hi100">
    <div id="total" class="wi100" style="height: 60%;">
      <div class="ent-basic">
        <div class="wi40">
          <div class="wi100 hi100 pt-3 ent-desc-right">
            <div class="wi55 c-white" style="height: 49%">
              <div class="f18"><span>企业综合质量等级</span></div>
              <div class="tac mt-2 wi100 item-flex-content">
                <div class="tac" style="width: 5vw;height: 5vw">
                  <dv-decoration-9 style="width: 100%;height: 100%">
                    <span style="font-size: 2.3rem;color: #e67e22;font-weight: 800">{{ level || '无' }}</span>
                  </dv-decoration-9>
                </div>
              </div>
            </div>
            <div class="wi55 c-white mt-4" style="height: 49%">
              <div class="f18">企业综合质量评分</div>
              <div class="tac mt-2 pl-4 pr-4">
                <dv-border-box-2 style="min-height: 80px">
                  <span v-if="score" style="font-size: 3.5rem;color: #e67e22;font-weight: 800">
                    <count-to  :startVal="0" :endVal="Number(score)"></count-to>
                    </span>
                  <span class="tac" style="font-size: 3.5rem;color: #e67e22;font-weight: 400" v-else>--</span>
                </dv-border-box-2>
              </div>
            </div>
          </div>
        </div>
        <div class="wi50 hi100">
          <div class="wi100 hi100 pb-5 ent-tag-out">
            <dv-border-box-12 class="ent-tag p-4" v-for="(item) in tagList.filter(item => item.type !== '99')"
                              :key="item.type">
              <div class="f16 c-white pl-3 fwb">{{ item.name }}</div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>
    <dv-decoration-10 class="mt-3" style="width:100%;height:2px;"/>
    <div class="ent-desc-bottom">
      <div id="positiveChart" class="wi33 hi100"></div>
      <div id="noneChart" class="wi33 hi100"></div>
      <div id="negativeChart" class="wi33 hi100"></div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  name: 'enterprise-total',
  props: {
    tagList: {
      type: Array,
      default: () => {
        return []
      }
    },
    level: {
      type: String,
      default: () => {
        return ''
      }
    },
    score: {
      type: String,
      default: () => {
        return ''
      }
    },
    enterpriseId: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  components: {
    CountTo
  },
  data() {
    return {
      enterpriseInfo: {
        enterpriseName: '汉唐信通（北京）科技有限公司',
        imageUrl: 'https://image.qcc.com/logo/08fde787c90ca400fd18c067daf207f2.jpg?x-oss-process=style/logo_120'
      },
      // score: 78.4,
      colors: ['#f56c6c', '#e6a23c', '#5cb87a', '#1989fa', '#6f7ad3', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      result: {
        level: '良+'
      },
      posInstance: {},
      negInstance: {},
      noneInstance: {},
      ratio: {},
      config: {}
    }
  },
  watch: {
    tagList(list) {
      let tempList = [...list]
      let data = []
      let config = {}
      tempList.forEach(item => {
        if (item.type !== '99') {
          data.push([
            `<dv-border-box-13><div class="ent-tag">
              <div class="wave"></div>
          <div class="f18 c-white pl-3">${item.name}</div>
        </div></dv-border-box-13>`
          ])
        }
      })
      config.data = data
      config.rowNum = 7
      config.oddRowBGC = ''
      config.evenRowBGC = ''
      config.align = ['center']
      this.config = {...config}

    }
  },
  mounted() {
    // this.drawPositiveChart()
    // this.drawNoneChart()
    // this.drawNegativeChart()
    this.initNewsRatio()
    // window.onresize = function () {
    //   this.posInstance.resize()
    //   this.negInstance.resize()
    //   this.noneInstance.resize()
    // }
  },
  methods: {
    drawPositiveChart() {
      let posDom = document.getElementById('positiveChart')
      this.posInstance = this.echarts.init(posDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            splitNumber: 4,
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            // 指针
            pointer: {
              width: 4
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return value.toFixed(0) + '%积极舆论'
              }
            },
            data: [
              {
                value: this.ratio.positiveRatio * 100,
                // name: '积极舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#2ed573'
                }
              }
            ]
          }
        ]
      }
      this.posInstance.setOption(option)
    },
    drawNoneChart() {
      let noneDom = document.getElementById('noneChart')
      this.noneInstance = this.echarts.init(noneDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            splitNumber: 4,
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            // 指针
            pointer: {
              width: 4
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return value.toFixed(0) + '%中立舆论'
              }
            },
            data: [
              {
                value: this.ratio.noneRatio * 100,
                // name: '中立舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#409eff'
                }
              }
            ]
          }
        ]
      }
      this.noneInstance.setOption(option)
    },
    drawNegativeChart() {
      let negDom = document.getElementById('negativeChart')
      this.negInstance = this.echarts.init(negDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            splitNumber: 4,
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            // 指针
            pointer: {
              width: 4
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return (value * 100).toFixed(0) + '%消极舆论'
              }
            },
            data: [
              {
                value: this.ratio.negativeRatio * 100,
                // name: '消极舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#ff6348'
                }
              }
            ]
          }
        ]
      }
      this.negInstance.setOption(option)
    },
    initNewsRatio() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.NEWS.RATIO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.ratio = data['result']
          this.drawPositiveChart()
          this.drawNoneChart()
          this.drawNegativeChart()
        }
      })
    }

  }
}
</script>

<style scoped>
#total {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.ent-basic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ent-desc {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
}

.ent-desc-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /*flex-direction: column;*/
  /*flex-wrap: wrap;*/
}

.ent-tag-out {
  display: flex;
  /*flex-direction: column;*/
  flex-wrap: wrap;
  justify-items: center;
  justify-content: space-between;
}

.ent-desc-bottom {
  padding: 10px 5% 10px 5%;
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ent-tag {
  display: flex;
  justify-content: center;
  height: 19%;
  width: 100%;
  line-height: 100%;
  padding: 5%;
  /*background-color: #409eff;*/
  /*margin-top: 1rem;*/
}

.item-flex-content {
  display: flex;
  justify-content: center;
}

.wave {
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  /*margin: 0 auto;*/
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 20px;
  color: #fff;
  background: #06c;
  background-size: 100%;
  animation: wave 3s linear infinite;
}

@keyframes wave {
  0% {
    box-shadow: 0 0 0 0 rgba(245, 226, 226, 1), 0 0 0 0 rgba(250, 189, 189, 1);
  }
  50% {
    box-shadow: 0 0 0 2px rgba(245, 226, 226, .5), 0 0 0 0 rgba(250, 189, 189, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(245, 226, 226, 0), 0 0 0 8px rgba(245, 226, 226, 0);
  }
}
</style>
