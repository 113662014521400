<template>
  <div id="newsList" class="wi100 hi100">
    <dv-scroll-board v-if="newsList.length > 0" style="padding: 0px" :config="dataConfig"/>
  </div>
</template>

<script>
export default {
  name: 'news-list',
  props: {
    newsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dataConfig: {
        index: false,
        headerHeight: 35,
        columnWidth: [450],
        align: ['center','center'],
        rowNum: 8,
        // 头部背景色
        // headerBGC: '#d35400',
        oddRowBGC: '',
        evenRowBGC: '#34495e'
      },
      // resultList: [],
      responseList: []
    }
  },
  created () {
    // this.initNews(this.enterpriseId)
  },
  mounted () {
    let list = []
    this.newsList.forEach( item => {
      let itemList = [`<a href="${item.url}" target="_blank" type="info" style="color:#FFFFFF">${item.title}</a>`, item.source,item.publishTime]
      list.push(itemList)
    })
    let dataConfig = {...this.dataConfig}
    dataConfig.data = [...list]
    this.dataConfig = dataConfig
    if (!this.newsList || this.newsList.length < 0) {
      this.defaultDraw()
    }
  },
  watch: {
    newsList: {
      handler: function (dataList) {
        let list = []
        dataList.forEach( item => {
          let itemList = [`<a href="${item.url}" target="_blank" type="info" style="color:#FFFFFF">${item.title}</a>`, item.source,item.publishTime]
          list.push(itemList)
        })
        let dataConfig = {...this.dataConfig}
        dataConfig.data = [...list]
        this.dataConfig = dataConfig
        // this.resultList = list
      }
    }
  },
  methods: {
    initNews (val) {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: val
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let resultList = [...data['result']['news']]
          let list = []
          resultList.forEach(item => {
            let itemList = [item.title, item.source,item.publishTime]
            list.push(itemList)
          })
          let dataConfig = {...this.dataConfig}
          dataConfig.data = [...list]
          this.dataConfig = dataConfig
        }
      })
    },
    defaultDraw() {
      let chartDom = document.getElementById('newsList')
      let myChart = this.echarts.init(chartDom)
      let option = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              color: '#f0f0f0',
              style: {
                text: '该企业暂无舆论信息',
                fontSize: 40,
                fontWeight: 'bold',
                lineDash: [0, 100],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409eff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
 /deep/ .header {
    padding: 0px;
  }
</style>
